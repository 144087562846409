/* .dark-background {
  background-color: #333 !important;
  color: #333;
} */

.dark-background .rdrCalendarWrapper,
.dark-background .rdrDefinedRangesWrapper,
.dark-background .rdrStaticRange,
.dark-background .rdrDateDisplayWrapper,
.dark-background .rdrNextPrevButton,
.dark-background .rdrDayNumber,
.dark-background .rdrDayNumber:hover,
.dark-background .rdrDay {
  background-color: rgba(255, 255, 255, 0);
  /* border: 1px solid rgba(255, 255, 255, 0.01); */
  color: white;
}

.rdrCalendarWrapper,
.dark-background .rdrDefinedRangesWrapper,
/* .rdrStaticRange, */
.dark-background .rdrDateDisplayWrapper {
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.dark-background .rdrStaticRange {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.dark-background .rdrDayNumber span {
  color: rgba(255, 255, 255, 0.5);
}

.dark-background .rdrDateInput input {
  color: white;
}

.dark-background .rdrDateInput {
  background-color: #333;
}

/* .rdrStaticRange:hover,
.rdrStaticRangeSelected:hover, */
.dark-background .rdrStaticRangeLabel:hover {
  /* background-color: rgba(255, 255, 255, 0.5) !important; */
  background-color: rgba(255, 255, 255, 0.3) !important;
  /* background: red !important;
  background-image: linear-gradient(red) !important; */
}

.dark-background .rdrStaticRangeSelected {
  background: rgba(255, 255, 255, 0.2);
}

.dark-background .rdrStaticRangeSelected .rdrStaticRangeLabel {
  color: white !important;
}

.dark-background button:hover {
  /* background-color: black; */
  /* background-image: linear-gradient(black); */
  /* background: black; */
}

.dark-background .rdrDayNumber span {
  color: white !important;
  /* background-color: rgba(255, 255, 255, 0.4); */
}

.dark-background .rdrInRange,
.dark-background .rdrStartEdge,
.dark-background .rdrEndEdge {
  background-color: rgba(255, 255, 255, 0.2);
}

.dark-background .rdrDateDisplayItemActive,
.dark-background .rdrDateDisplayItemActive  {
  border-color: rgba(255, 255, 255, 0.2) !important;
  border: 1px solid black;
}

.dark-background .rdrNextPrevButton,
.dark-background .rdrPprevButton {
  background-color: rgba(255, 255, 255, 0.2);
}

.dark-background .rdrYearPicker,
.dark-background .rdrMonthPicker {
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
}

.dark-background .rdrWeekDay {
  color: rgba(255, 255, 255, 0.3);
}

.dark-background select,
.dark-background option {
  color: white !important;
}

.dark-background .rdrInputRange input {
  background-color: #424242;
  color: rgba(255, 255, 255, 1);
}

.dark-background .rdrInputRange input:hover,
.dark-background .rdrInputRange input:focus,
.dark-background .rdrInputRange input:active {
  background-color: #424242;
  color: white;
}

.dark-background .rdrDayToday .rdrDayNumber span:after {
  background-color: white;
}

.rdrDefinedRangesWrapper {
  overflow-y: scroll;
  max-height: 400px;
}

.dark-background .rdrMonthAndYearWrapper option {
  color: white;
  background: #55555c;
}

