.rdrDateDisplayWrapper {
  display: none;
}

.rdrCalendarWrapper.dark-background {
  background-color: #424242 !important;
}

.dark-background .rdrPprevButton i {
  color: white !important;

  border-color: transparent rgb(255, 255, 255) transparent transparent;
}

.dark-background .rdrNextButton i {
  border-color: transparent transparent transparent rgb(255, 255, 255) !important;
}

.dark-background .rdrSelected {
  color: rgba(255, 255, 255, 0.3) !important;
}

.dark-background .rdrDayStartPreview .rdrDayEndPreview {
  color: white !important;
}

.dark-background .rdrDayHovered {
  color: white !important;
}

.dark-background .rdrDayStartPreview {
  color: white !important;
}

/* rdrDayEndPreview */
