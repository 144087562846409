/* Global Styles */
/* :root {
  --primary-color: #17a2b8;
  --dark-color: #343a40;
  --light-color: #f4f4f4;
  --danger-color: #dc3545;
  --success-color: #28a745;
} */

/* :root {
  --primary-color: #00adb5;
  --dark-color: #222831;
  --light-color: #eeeeee;
  --danger-color: #dc3545;
  --success-color: #28a745;
} */

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Roboto', 'Raleway', sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  background-color: #fff;
  color: #333;
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

ul {
  list-style: none;
}

img {
  width: 100%;
}

main {
  width: 90vw;
  /* max-width: 95% !important; */
}

/* Utilities */
.container {
  /* max-width: 1250px; */
  margin: auto;
  overflow: hidden;
  /* padding: 0 2rem; */
  /* margin-top: 1rem; */
  /* margin-bottom: 3rem; */
  position: relative;
  z-index: 0;
}

/* Text Styles*/
.x-large {
  font-size: 4rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.large {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.lead {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.text-center {
  text-align: center;
}

.text-primary {
  color: var(--primary-color);
}

.text-dark {
  color: var(--dark-color);
}

/* Padding */
.p {
  padding: 0.5rem;
}
.p-1 {
  padding: 1rem;
}
.p-2 {
  padding: 2rem;
}
.p-3 {
  padding: 3rem;
}
.py {
  padding: 0.5rem 0;
}
.py-1 {
  padding: 1rem 0;
}
.py-2 {
  padding: 2rem 0;
}
.py-3 {
  padding: 3rem 0;
}

/* Margin */
.m {
  margin: 0.5rem;
}
.m-1 {
  margin: 1rem;
}
.m-2 {
  margin: 2rem;
}
.m-3 {
  margin: 3rem;
}
.my {
  margin: 0.5rem 0;
}
.my-1 {
  margin: 1rem 0;
}
.my-2 {
  margin: 2rem 0;
}
.my-3 {
  margin: 3rem 0;
}

.btn {
  display: inline-block;
  background: var(--light-color);
  color: #333;
  padding: 0.4rem 1.3rem;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  margin-right: 0.5rem;
  transition: opacity 0.2s ease-in;
  outline: none;
  border-radius: 5px;
}

.badge {
  font-size: 0.8rem;
  padding: 0.1rem;
  text-align: center;
  margin: 0.3rem;
  background: var(--light-color);
  color: #333;
}

.alert {
  padding: 0.8rem;
  margin: 1rem 0;
  opacity: 0.9;
  background: var(--light-color);
  color: #333;
}

.btn-primary,
.bg-primary,
.badge-primary,
.alert-primary {
  background: var(--primary-color);
  color: #fff;
}

.btn-light,
.bg-light,
.badge-light,
.alert-light {
  background: var(--light-color);
  color: #333;
}

.btn-dark,
.bg-dark,
.badge-dark,
.alert-dark {
  background: var(--dark-color);
  color: #fff;
}

.btn-danger,
.bg-danger,
.badge-danger,
.alert-danger {
  background: var(--danger-color);
  color: #fff;
}

.btn-success,
.bg-success,
.badge-success,
.alert-success {
  background: var(--success-color);
  color: #fff;
}

.btn-white,
.bg-white,
.badge-white,
.alert-white {
  background: #fff;
  color: #333;
  border: #ccc solid 1px;
}

.btn:hover {
  opacity: 0.8;
}

.bg-light,
.badge-light {
  border: #ccc solid 1px;
}

.round-img {
  border-radius: 50%;
}

.line {
  height: 1px;
  background: #ccc;
  margin: 1.5rem 0;
}

/* Overlay */
.dark-overlay {
  /* background-color: rgba(0, 0, 0, 0.7); */
  /* background-color: rgba(255, 255, 255, 0.87); */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Forms */
.form .form-group {
  margin: 1.2rem 0;
}

.form .form-text {
  display: block;
  margin-top: 0.3rem;
  color: #888;
}

.form input[type='text'],
.form input[type='email'],
.form input[type='password'],
.form input[type='date'],
.form select,
.form textarea {
  display: block;
  width: 100%;
  padding: 0.4rem;
  font-size: 1.2rem;
  border: 1px solid #ccc;
}

.form input[type='submit'],
button {
  font: inherit;
}

.form .social-input {
  display: flex;
}

.form .social-input i {
  padding: 0.5rem;
  width: 4rem;
}

.form .social-input i.fa-twitter {
  color: #38a1f3;
}
.form .social-input i.fa-facebook {
  color: #3b5998;
}
.form .social-input i.fa-instagram {
  color: #3f729b;
}
.form .social-input i.fa-youtube {
  color: #c4302b;
}
.form .social-input i.fa-linkedin {
  color: #0077b5;
}

.table th,
.table td {
  padding: 1rem;
  text-align: left;
}

.table th {
  background: var(--light-color);
}

/* Navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem 2rem;
  position: fixed;
  z-index: 1;
  width: 100%;
  top: 0;
  /* border-bottom: solid 1px var(--primary-color); */
  opacity: 0.9;
  height: 50px;
  padding-left: 64px;
}

.navbar ul {
  display: flex;
}

.navbar a {
  color: #fff;
  padding: 0.45rem;
  margin: 0 0.25rem;
}

.navbar a:hover {
  color: var(--primary-color);
}

.navbar .welcome span {
  margin-right: 0.6rem;
}

.leftnav {
  background-color: var(--dark-color);
}
.leftnav i {
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
  color: #ffffff;
  background-color: var(--dark-color);
}
.leftnav i:hover {
  /* background-color: #bcbcbc; */
  color: #bcbcbc;
}

/* Landing Page */
.landing {
  color: white;
  position: relative;
  /* background: url('./img/showcase.jpg') no-repeat center center/cover; */
  /* background-image: url('./img/bg-1.png'); */
  /* background-color: #21285a; */
  background-color: #e9e9e9;
  /* background-image: url(bg-1.png); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 1;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing-inner {
  /* color: #fff; */
  color: rgb(33, 33, 33);
  height: 100%;
  width: 100%;
  max-width: 100%;
  border-radius: 0px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: rgb(245, 245, 245);
}

@media only screen and (min-width: 768px) {
  .landing-inner {
    /* color: #fff; */

    background-color: rgb(245, 245, 245);
    color: rgb(33, 33, 33);
    height: 550px;
    width: 520px;
    max-width: 100%;
    border-radius: 15px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}

/* Profiles Page */
.profile {
  display: grid;
  grid-template-columns: 2fr 4fr 2fr;
  align-items: center;
  grid-gap: 2rem;
  padding: 1rem;
  line-height: 1.8;
  margin-bottom: 1rem;
}

/* Profile Page */
.profile-grid {
  display: grid;
  grid-template-areas:
    'top top'
    'about about'
    'exp edu'
    'github github';
  grid-gap: 1rem;
}

.profile-top {
  grid-area: top;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.profile-top img {
  width: 250px;
}

.profile-top .icons a {
  color: #fff;
  margin: 0 0.3rem;
}

.profile-top .icons a:hover {
  color: var(--dark-color);
}

.profile-about {
  grid-area: about;
  text-align: center;
}

.profile-about .skills {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.profile-exp {
  grid-area: exp;
}

.profile-edu {
  grid-area: edu;
}

.profile-exp h2,
.profile-edu h2 {
  margin-bottom: 1rem;
}

.profile-exp > div,
.profile-edu > div {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: #ccc 1px dotted;
}

.profile-exp > div:last-child,
.profile-edu > div:last-child {
  border: 0;
}

.profile-exp p,
.profile-edu p {
  margin: 0.5rem 0;
}

.profile-github {
  grid-area: github;
}

.profile-github .repo {
  display: flex;
}

.profile-github .repo > div:first-child {
  flex: 7;
  flex-basis: 70%;
}

.profile-github > div:last-child {
  flex: 3;
  flex-basis: 20%;
}

/* Posts Page */
.post-form .post-form-header {
  background: var(--primary-color);
  padding: 0.5rem;
}

.post {
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-gap: 2rem;
  align-items: center;
}

.post > div:first-child {
  text-align: center;
}

.post img {
  width: 100px;
}

.post .comment-count {
  background: var(--light-color);
  color: var(--primary-color);
  padding: 0.1rem 0.2rem;
  border-radius: 5px;
  font-size: 0.8rem;
}

.post .post-date {
  color: #aaa;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
}

/* Mobile Styles */
@media (max-width: 700px) {
  /* .container {
    margin-top: 8rem;
  } */

  .hide-sm {
    display: none;
  }

  /* Text Styles */
  .x-large {
    font-size: 3rem;
  }

  .large {
    font-size: 2rem;
  }

  .lead {
    font-size: 1rem;
  }

  /* Navbar */
  .navbar {
    display: block;
    text-align: center;
  }

  .navbar ul {
    text-align: center;
    justify-content: center;
  }

  .navbar h1 {
    margin-bottom: 1rem;
  }

  .navbar .welcome {
    display: none;
  }

  /* Profiles Page */
  .profile {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .profile ul {
    display: none;
  }

  /* Profile Page */

  .profile-top img,
  .profile img {
    width: 200px;
    margin: auto;
  }

  .profile-grid {
    grid-template-areas:
      'top'
      'about'
      'exp'
      'edu'
      'github';
  }

  .profile-about .skills {
    flex-direction: column;
  }

  .dash-buttons a {
    display: block;
    width: 100%;
    margin-bottom: 0.2rem;
  }

  .post {
    grid-template-columns: 1fr;
  }
  .post a,
  .post button {
    padding: 0.3rem 0.4rem;
  }
}

ti {
  padding: 10px;
}

.select-css {
  float: left;
  display: block;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  color: #e5e5e5;
  line-height: 1.3;
  padding: 0.6em 1.4em 0.5em 0.8em;
  /* width: 100%; */
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  /* border-radius: 0.5em; */
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--dark-color);
  /* background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
    linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%); */
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}
.select-css::-ms-expand {
  display: none;
}
.select-css:hover {
  border-color: #888;
}
.select-css:focus {
  border-color: #aaa;
  box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #e5e5e5;
  outline: none;
}
.select-css option {
  font-weight: normal;
}

.navbar-mid-section {
  float: left;
  left: 0px;
  text-align: left;
  justify-content: left;
  /* background-color: #dc3545; */
}

.footer {
  /* background-image: linear-gradient(
    to bottom right,
    rgba(34, 40, 49, 0.9),
    rgba(34, 40, 49, 0.9)
  ); */
  /* background-image: linear-gradient(
    to bottom right,
    rgba(34, 40, 49, 1),
    rgba(34, 40, 49, 0.9)
  ); */
  /* background-color: rgb(66, 66, 66); */
  /* background-color: #303030; */
  background-color: #fafafa;
  /* background-color: #222831; */
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  width: 100%;
  /* height: 150px; */
  border-top: 1px solid rgba(210, 210, 210, 0.5);
  /* border-top: 1px solid rgba(34, 40, 49, 0.5); */
  /* border-top: 1px solid #00adb5; */
  /* color: #eeeeee; */
  color: rgb(33, 33, 33);
  text-align: center;
  padding: 100px;
}

/* For desktop: */
.col-1 {
  width: 8.33%;
}
.col-2 {
  width: 16.66%;
}
.col-3 {
  width: 25%;
}
.col-4 {
  width: 33.33%;
}
.col-5 {
  width: 41.66%;
}
.col-6 {
  width: 50%;
}
.col-7 {
  width: 58.33%;
}
.col-8 {
  width: 66.66%;
}
.col-9 {
  width: 75%;
}
.col-10 {
  width: 83.33%;
}
.col-11 {
  width: 91.66%;
}
.col-12 {
  width: 100%;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*='col-'] {
    width: 100%;
  }
}

/* Spinner */
.loader {
  border: 12px solid #f3f3f3; /* Light grey */
  /* border-top: 16px solid #3498db; Blue */
  /* border-top: 12px solid #00adb5; */
  border-top: 12px solid rgb(178, 67, 147);
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin: auto auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* .MuiSvgIcon-root {
  background-color: red !important;
  color: white !important;
} */

/* .MuiPickersBasePicker-container {
  background-color: black !important;
  color: white !important;
} */

/* .MuiButtonBase-root,
.MuiIconButton-root,
.MuiPickersDay-day,
.MuiPickersDay-hidden {
  background-color: black !important;
  color: white !important;
} */

.footer-cell:last-child {
  border-bottom-right-radius: 5px;
}

.header-cell:last-child {
  border-top-right-radius: 5px;
}

/* width */
::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}

/* Track */
::-webkit-scrollbar-track {
  /* background: #303030; */
  background: rgba(0, 0, 0, 0);
  /* border-radius: 10px; */
}

/* Handle */
::-webkit-scrollbar-thumb {
  /* background: #555; */
  /* background: rgba(220, 220, 235, 0.5); */
  /* background: rgba(167, 38, 131, 0.5); */
  background: rgba(42, 82, 163, 0.3);
  border-radius: 10px;
  /* border: 1px solid rgba(42, 82, 163, 0.1); */
  border: 1px solid rgba(255, 255, 255, 0.5);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /* background: #888; */
  /* background: rgba(255, 255, 255, 0.5); */
  background: rgba(220, 220, 235, 0.7);
}

::-webkit-scrollbar-corner {
  /* background: #303030; */
  background: rgba(180, 180, 180, 0);
}

/* ::-webkit-scrollbar-track-piece {

  background: rgba(0, 0, 0, 0);

} */

.fa-pen {
  color: grey;
}

.fa-pen:hover {
  color: #bcbcbc;
}

.hover-grey:hover {
  color: #bcbcbc;
}

.transparent-hover-grey:hover {
  color: #bcbcbc;
}

.transparent-hover-grey {
  color: rgba(255, 255, 255, 0);
}

.hover-light-grey:hover {
  color: #ebebeb;
}

.hover-transparent-white:hover {
  background-color: rgba(255, 255, 255, 0.15) !important;
}

.hover-col-resize:hover {
  background-color: rgba(150, 150, 150, 0.2) !important;
}

.dataSource .MuiAutocomplete-input,
.dataSource .MuiAutocomplete-inputRoot {
  font-size: 15px !important;
  /* color: red !important; */
}

.MuiAutocomplete-option {
  font-size: 15px !important;
  /* color: red !important; */
}

/* .MuiInput-root,
.MuiInput-root:focus,
.MuiInput-root:hover,
.MuiInput-underline,
.MuiInput-underline:hover, */
.filter-by-team-member .MuiInput-root,
.filter-by-team-member .MuiInput-underline:before {
  border-bottom: 0px solid rgba(255, 255, 255, 0) !important;
  border-bottom-color: rgba(255, 255, 255, 0) !important;
  /* color: red !important; */
  font-size: 15px !important;
}

.text-transform-unset {
  text-transform: unset !important;
}

/* .rdrInfiniteMonths.rdrMonthsVertical {
  height: 420px !important;
} */

.hover-backgroundcolor-grey {
  background-color: rgba(150, 150, 150, 0.1);
}

.hover-backgroundcolor-grey:hover {
  background-color: rgba(150, 150, 150, 0.14);
}

.hover-backgroundcolor-grey2:hover {
  background-color: rgba(150, 150, 150, 0.14);
}

.SimpleBarChart .recharts-label {
  fill: #21285a;
}

.nexustable-label {
  position: relative;
  width: fit-content;
  padding: 3px 10px;
  height: 26px;
  margin-right: 20px;
  color: #ffffff;
  background-color: #21285a;
  text-align: center;
  line-height: 26px;
  border-radius: 5px 0px 0px 5px;
  cursor: pointer;
}

/* .nexustable-label:after {
  content: '';
  position: absolute;
  left: 100%;
  top: 0px;
  width: 0px;
  height: 0px;
  border-top: 12.9px solid transparent;
  border-left-color: inherit;
  border-left-width: 10px;
  border-left-style: solid;
  border-bottom: 12.9px solid transparent;
  cursor: pointer;
} */

.nexustable-menu-label {
  font-size: 12px;
  position: relative;
  width: fit-content;
  padding: 3px 10px;
  height: 26px;
  margin: 5px 20px 5px 0px;
  color: #ffffff;
  /* background-color: #21285a; */
  text-align: center;
  line-height: 26px;
  border-radius: 5px 0px 0px 5px;
  cursor: pointer;
}

.nexustable-menu-label:after {
  content: '';
  position: absolute;
  left: 100%;
  top: 0px;
  width: 0px;
  height: 0px;
  border-top: 12.9px solid transparent;
  /* border-left: 10px solid; */
  border-left-color: inherit;
  border-left-width: 10px;
  border-left-style: solid;
  border-bottom: 12.9px solid transparent;
  cursor: pointer;
}

.blink {
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

/* The animation code */
@keyframes isTableShiftedTrue {
  from {
    margin-left: 0%;
  }
  to {
    margin-left: 40%;
  }
}

/* The element to apply the animation to */
.isTableShiftedTrue {
  margin-left: 40%;
  /* width: 100px;
  height: 100px; */
  /* background-color: red; */
  animation-name: isTableShiftedTrue;
  animation-duration: 0.5s;
}

@keyframes isTableShiftedFalse {
  from {
    margin-left: 40%;
  }
  to {
    margin-left: 0;
  }
}

/* The element to apply the animation to */
.isTableShiftedFalse {
  margin-left: 0;
  /* width: 100px;
  height: 100px; */
  /* background-color: red; */
  animation-name: isTableShiftedFalse;
  animation-duration: 0.5s;
}

@keyframes isTimeboxerOpenTrue {
  from {
    right: -1500px;
  }
  to {
    right: 0px;
  }
}

/* The element to apply the animation to */
.isTimeboxerOpenTrue {
  /* transform: scale(1); */
  right: 0px;
  /* width: 100px;
  height: 100px; */
  /* background-color: red; */
  animation-name: isTimeboxerOpenTrue;
  animation-duration: 0.5s;
  display: flex;
}

@keyframes isTimeboxerOpenFalse {
  from {
    right: 0px;
  }
  to {
    right: -1500px;
  }
}

/* The element to apply the animation to */
.isTimeboxerOpenFalse {
  /* transform: scale(1); */
  right: -1500px;
  /* width: 100px;
  height: 100px; */
  /* background-color: red; */
  animation-name: isTimeboxerOpenFalse;
  animation-duration: 0.5s;
  display: none;
}

@keyframes isTimeboxListOpenTrue {
  from {
    top: 100%;
  }
  to {
    top: 0%;
  }
}

/* The element to apply the animation to */
.isTimeboxListOpenTrue {
  /* transform: scale(1); */
  top: 0%;
  /* width: 100px;
  height: 100px; */
  /* background-color: red; */
  animation-name: isTimeboxListOpenTrue;
  animation-duration: 0.5s;
}

@keyframes isTimeboxListOpenFalse {
  from {
    top: 0%;
  }
  to {
    top: 100%;
  }
}

/* The element to apply the animation to */
.isTimeboxListOpenFalse {
  /* transform: scale(1); */
  top: 100%;
  /* width: 100px;
  height: 100px; */
  /* background-color: red; */
  animation-name: isTimeboxListOpenFalse;
  animation-duration: 0.5s;
}

.timeboxListDefault {
  top: 100%;
}

@keyframes isReportBuilderOpenTrue {
  from {
    right: -1500px;
  }
  to {
    right: 0px;
  }
}

/* The element to apply the animation to */
.isReportBuilderOpenTrue {
  /* transform: scale(1); */
  right: 0px;
  /* width: 100px;
  height: 100px; */
  /* background-color: red; */
  animation-name: isReportBuilderOpenTrue;
  animation-duration: 0.5s;
  display: flex;
}

@keyframes isReportBuilderOpenFalse {
  from {
    right: 0px;
  }
  to {
    right: -1500px;
  }
}

/* The element to apply the animation to */
.isReportBuilderOpenFalse {
  /* transform: scale(1); */
  right: -1500px;
  /* width: 100px;
  height: 100px; */
  /* background-color: red; */
  animation-name: isReportBuilderOpenFalse;
  animation-duration: 0.5s;
  display: none;
}

@keyframes isReportBuilderPageListOpenTrue {
  from {
    top: 100%;
  }
  to {
    top: 0%;
  }
}

/* The element to apply the animation to */
.isReportBuilderPageListOpenTrue {
  /* transform: scale(1); */
  top: 0%;
  /* width: 100px;
  height: 100px; */
  /* background-color: red; */
  animation-name: isReportBuilderPageListOpenTrue;
  animation-duration: 0.5s;
}

@keyframes isReportBuilderPageListOpenFalse {
  from {
    top: 0%;
  }
  to {
    top: 100%;
  }
}

/* The element to apply the animation to */
.isReportBuilderPageListOpenFalse {
  /* transform: scale(1); */
  top: 100%;
  /* width: 100px;
  height: 100px; */
  /* background-color: red; */
  animation-name: isReportBuilderPageListOpenFalse;
  animation-duration: 0.5s;
}

.ReportBuilderPageListDefault {
  top: 100%;
}

.isImageLoading {
  filter: blur(10px);
  clip-path: inset(0);
}
.isImageLoaded {
  filter: blur(0px);
  transition: filter 0.2s linear;
}
