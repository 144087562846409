.cursorGrab {
  /* cursor: move; */
  cursor: pointer;
  /* cursor: -webkit-grab; */
  /* cursor: grab; */
}
.cursorGrab:active {
  cursor: grabbing;
  cursor: -webkit-grabbing;
}
