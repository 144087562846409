.news-feed-action {
    /* color:red;
    transition: width 2s; */
    transform: scale(1);
    transition: 0.1s;
    cursor: pointer;
}

.news-feed-action:hover{
    transform: scale(1.2);
    transition: 0.1s;
}

.news-feed-menu {
    /* display:none;
    flex-direction: row;
    column-gap: 5px; */
    width:20px;
    transform: scale(0.8);
    transition: 0.1s;
}

.news-feed-menu:hover {
    /* display:flex;
    width: fit-content; */
    width: 140px;
    transform: scale(1);
    transition: 0.1s;
}

.show-on-hover {
    visibility: hidden;
}

.show-on-hover:hover{
    visibility:visible;
}

.hide-on-hover {
    transform: scale(1);
}

.hide-on-hover:hover {
    transform: scale(0);
}

.newsfeed-item-wrapper {
    width: 60%;
}

.newsfeed-item-icon {
    display: flex;
}

.child-comment-wrapper {
    padding: 5px 0px 0px 60px;
}

@media only screen and (max-width: 600px) {
    .newsfeed-item-wrapper {
        width: 100%;
    }
    .newsfeed-item-icon {
        display: none;
    }

    .child-comment-wrapper {
        padding: 0px;
    }

    .hide-in-mobile {
        display: none;
        visibility: hidden;
    }
  }